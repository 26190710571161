import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { DeleteChildRendered } from '../../common/renders/delete-child-rendered';
import { PrintChildRendered } from '../../common/renders/print-child-rendered';
import { ViewChildRendered } from '../../common/renders/view-child-rendered';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { CalendarService } from '../../configuration/calendar/calendar.service';
import { ReportsService } from "../reports.service";

import { UserLogin } from '../../common/models/UserLogin.Model';
import { Calendar } from '../../common/models/Calendar.Model';
import { Region } from '../../common/models/Region.Model';
import { Unit } from '../../common/models/Unit.Model';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { AdjustService } from '../../inventory/adjust/adjust.service';

@Component({
  selector: 'app-rep-ongoing',
  templateUrl: './rep-ongoing.component.html',
  styleUrls: ['./rep-ongoing.component.scss']
})
export class RepOngoingComponent implements OnInit {
  // Properties
  downloading: boolean = false;
  regionList: Region[];
  region: Region;
  userId: number;
  userLogin: UserLogin;
  unit: number = 0;
  type: number = 0;
  unitId: number = 0;
  nodeList: Unit[];
  selectedOriginUnitName: any;
  selectedOriginUnit: number;
  typeLevel: string;

  // ViewChild
  @ViewChild('CeqGrid', { static: false }) ceqGrid: CeqTableComponent;
  options: GridOptions;

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childPrintRenderer: PrintChildRendered,
      childViewRenderer: ViewChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
  }

  columnDefs = [
    {
      headerName: 'Region Origen',
      field: 'RegionOrigin',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Clasificación Origen',
      field: 'TypeOrigin',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: '*Nodo Origen',
      field: 'OutputName',
      width: 35,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Region Destino',
      field: 'RegionDestiny',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Clasificación Destino',
      field: 'TypeDestiny',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: '*Nodo Destino',
      field: 'InputName',
      width: 35,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Transporte',
      field: 'Tractor',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: '*Remisión',
      field: 'Remission',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Equipo',
      field: 'ContainerCode',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'PT',
      field: 'PT',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'EV',
      field: 'EV',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Total',
      field: 'Total',
      width: 10,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: 'Estatus',
      field: 'Status',
      width: 5,
      cellClass: 'align-start',
      editable: false,
      resizable: true
    },
    {
      headerName: '*Fecha Cierre',
      field: 'CloseDate',
      width: 20,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true,
      cellRenderer: (params: any) => moment(params.data.CloseDate).format('YYYY-MM-DD HH:mm')
    },
    {
      headerName: '*Fecha Venta',
      field: 'SellDate',
      width: 15,
      cellClass: 'align-start',
      editable: false,
      resizable: true,
      sortable: true,
      cellRenderer: (params: any) => moment(params.data.SellDate).format('YYYY-MM-DD')
   }
  ];

  constructor(private reportsService: ReportsService ,
    private calendarService : CalendarService,
    private router : Router,
    private settingsService:SettingsService,
    private globalFunctionService: GlobalFunctionsService,
    private adjustService:AdjustService ) { }

  async ngOnInit(){
    this.globalFunctionService.canOperate();
    this.globalFunctionService.hasFSBRPending();
   await  this.calendarService.CalendarInventoryInProgress().subscribe((calendar: Calendar[] )=>{ 
      if (calendar.length==1)
      {
        this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en toma de inventario fisico.`, 'warning');
        this.router.navigate([`/home`]);
      }
    });

    await this.adjustService.validateAdjustActive(this.settingsService.getUser().UnitId)
      .toPromise()
      .then(resp => {
        if (resp.AdjustCode !== '') {
          this.globalFunctionService.messageToast(`No es posible realizar esta operación, se encuentra en compulsa.`, 'warning');
          this.router.navigate([`/home`]);
        }
      });

    this.getRegions();
    this.typeLevel = 'FAB';
    this.getNodes(this.typeLevel);
  }

  gridEmiter(gridOptions: GridOptions) {
    this.options = gridOptions;
    this.ceqGrid.data = [];
  }

  applyFilters(event: any) {
    if (this.typeLevel === 'FAB') {
      this.selectedOriginUnit = 2;
    }
    if (this.typeLevel === 'CEVE') {
      this.selectedOriginUnit = 1;
    }
    this.selectedOriginUnitName = '';
    this.getNodes(this.typeLevel);
  }

  download() {
    this.downloading = true;
    if (this.selectedOriginUnit === undefined) {
      if (this.typeLevel === 'FAB') {
        this.selectedOriginUnit = 2;
      }
      if (this.typeLevel === 'CEVE') {
        this.selectedOriginUnit = 1;
      }
    }

    this.reportsService.getOngoingReportExcel(this.selectedOriginUnit, this.unit).subscribe((resp: any) => {
      if (resp == null) {
        this.globalFunctionService.messageToast(`No se ha encontrado información.`, 'warning');
        this.downloading = false;
        return;
      }
      console.log("termino llamada");

      let blob = new Blob([resp], { type: resp.type });

      const anchor = document.createElement('a');

      if (this.selectedOriginUnit < 3) {
        this.region = this.regionList.find(r => r.UnitId.toString() === this.unit.toString());
        anchor.download = `Reporte Transito region ${this.region.RegionName}.xlsx`;
      } else {
        anchor.download = `Reporte Transito ${this.selectedOriginUnitName.UnitDescription}.xlsx`;
      }
      anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
      anchor.click();
      this.downloading = false;
    }, err => {
      this.globalFunctionService.messageToast(`Error en la invocación.`, 'warning');
      this.downloading = false;
      console.log(err);
    });
  }

  getRegions() {
    this.reportsService.getRegionUnit().subscribe(data => {
      this.regionList = data;
      this.unit = 1000; // Se coloca la primer region por default
    },(err)=>{},
      () => { this.applyFilters(undefined); });
  }

  getNodes(level: string) {
    //this.reportsService.getNodesFabCeve(level).subscribe(data => {
    //  this.nodeList = data;
    //});
    //this.unitId = undefined;
    if (level == '') {
      this.globalFunctionService.messageToast(`Seleccione una clasificación de nodo válida.`, 'warning');
      return;
    }
    this.nodeList = [];
    this.reportsService.getNodeByRegionLevel(
      this.unit,
      level
    ).toPromise()
      .then((resp: Unit[]) => {
        this.nodeList = resp;
      })
      .catch(() => {
        this.globalFunctionService.messageToast(`No se encontraron nodos.`, 'warning');
      });
  }

  onOriginUnitChange(value: any) {
    this.selectedOriginUnit = value.UnitId;
    // TODO: Consulta para llenar la tabla
    //this.ceqGrid.endPoint = `report/ongoing/${this.selectedOriginUnit}`;
    //this.ceqGrid.refreshTable();
  }

  onOriginUnitCleared() {
    this.selectedOriginUnit = undefined;
  }

  cmbUnitChange(event: any) {
    if (event == 0) {
      this.typeLevel = 'FAB';
      this.selectedOriginUnit = 2;
      this.selectedOriginUnitName = '';
      this.getNodes(this.typeLevel);
    }
    if (event == 1) {
      this.typeLevel = 'CEVE';
      this.selectedOriginUnit = 1;
      this.selectedOriginUnitName = '';
      this.getNodes(this.typeLevel);
    }
  }
}
